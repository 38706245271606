import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'views/Head';
import LeasingHeader from 'views/LeasingHeader';

const LeasingPage: FC<PageProps> = () => (
  <>
    <Head title="Leasing i Faktoring" />
    <LeasingHeader />
  </>
);

export default LeasingPage;
