import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import List from 'components/List';
import { SectionTitle, SectionSubtitle } from 'components/Common';
import {
  Wrapper,
  Content,
  Column,
  Text,
  StyledList,
  LineList,
  CoverWrapper,
  Cover,
} from './styles';

interface CoverQuery {
  file: {
    childImageSharp: {
      fluid: FluidObject | FluidObject[];
    };
  };
}

const data = graphql`
  {
    file(relativePath: { eq: "images/leasing.png" }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 366, maxHeight: 465) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const LeasingHeaderView: FC = () => {
  const { file } = useStaticQuery<CoverQuery>(data);

  return (
    <Wrapper>
      <Content>
        <Column id="leasing">
          <SectionTitle>Leasing</SectionTitle>
          <SectionSubtitle>
            Leasing to wsparcie finansowe bez odsetek!
          </SectionSubtitle>
          <Text>
            Leasing to najprostsza, najszybsza i najbardziej korzystna forma
            finansowania Twojej inwestycji.
          </Text>
          <StyledList
            as={List}
            title="Co wyróżnia leasing:"
            items={[
              'Raty z leasingu rozliczysz w kosztach',
              'Krótki staż Twojej firmy nie jest problemem',
              'Cała rata leasingowa bez odsetek',
            ]}
          />
          <StyledList
            as={List}
            title="Leasing można otrzymać na różne cele:"
            items={['Samochód', 'Urządzenia i maszyny', 'Nieruchomość']}
            arrow
          />
        </Column>
        <CoverWrapper>
          <Cover fluid={file.childImageSharp.fluid} draggable={false} />
        </CoverWrapper>
        <LineList
          as={List}
          title="Pomożemy wybrać dla Ciebie najlepszy rodzaj leasingu:"
          items={['Leasing operacyjny', 'Leasing finansowy', 'Leasing zwrotny']}
          line
        />
        <Column id="faktoring">
          <SectionTitle>Faktoring</SectionTitle>
          <SectionSubtitle>
            Problem z płynnością finansową to przeszłość!
          </SectionSubtitle>
          <Text>
            Jeśli Twoja firma prowadzi sprzedaż towarów albo usługi z odroczonym
            terminem płatności to możemy zaproponować Ci faktoring.
          </Text>
          <StyledList
            as={List}
            title="W faktoringu ważne jest to, że:"
            items={[
              'Kontrahentem musi być firma bądź instytucja',
              'Sprzedaż usług musi być hurtowa',
            ]}
          />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default LeasingHeaderView;
