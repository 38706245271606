import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';
import { SectionWrapper, ContentWrapper } from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  margin: 13.5rem auto 10rem;
`;

export const Content = styled(ContentWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Column = styled.div`
  max-width: 480px;
`;

export const Text = styled.p`
  margin: 0 0 2rem;
  font-size: 1.3rem;
`;

export const StyledList = styled.div`
  margin-bottom: 2rem;
`;

export const LineList = styled.ul`
  width: 100%;
  margin: 0 0 3rem;
`;

export const CoverWrapper = styled.div`
  flex: 1 0 100%;
  max-width: 490px;
  margin: 0 auto 4rem;

  ${({ theme }) => theme.mq.mobile} {
    flex: 1 0 300px;
    margin-bottom: 10rem;
    transform: translateY(7.5rem);
  }
`;

interface CoverProps {
  fluid: FluidObject | FluidObject[];
}

export const Cover = styled(Img)<CoverProps>`
  max-width: 366px;
  border-radius: 140px 15px;
`;
